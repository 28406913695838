import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/global/layout/Layout';
import SEO from '../components/global/seo';
import Customers from '../components/global/customers/Customers';
import InfoboxFragmentWrapper from '../components/global/InfoboxFragmentWrapper/InfoboxFragmentWrapper';
import InfoSectionFragmentWrapper from '../components/global/InfoSectionFragmentWrapper/InfoSectionFragmentWrapper';
import CustomerStoryFragmentWrapper from '../components/global/CustomerStoryFragmentWrapper/CustomerStoryFragmentWrapper';
import InfoCardsFragmentWrapper from '../components/global/InfoCardsFragmentWrapper/InfoCardsFragmentWrapper';
import CTAWrapper from '../components/home-page/CtaWrapper/CTAWrapper';
import MasterCTA from '../components/global/CTA/MasterCTA/MasterCTA.component';
import * as S from '../components/enterprise-chatbot/styles';
import { Container } from '../components/global/customers/styles';

const EnterpriseChatbot = ({ data }) => {
	const {
		heroSection,
		section2Title,
		section2Images,
		infoSection1,
		customerTestimonial1,
		infoCards1,
		infoSection2,
		customerTestimonial2,
		cta,
		seo,
	} = data.contentfulEnterpriseChatbot;
	return (
		<Layout>
			<SEO {...seo} />
			<InfoboxFragmentWrapper data={heroSection} />
			<S.CustomersWrapper>
				<Customers
					section2Images={section2Images}
					section2Title={section2Title}
				/>
			</S.CustomersWrapper>
			<Container>
				<InfoSectionFragmentWrapper data={infoSection1} />
				<CustomerStoryFragmentWrapper data={customerTestimonial1} />
				<InfoCardsFragmentWrapper data={infoCards1} />
				<InfoSectionFragmentWrapper data={infoSection2} />
				<CustomerStoryFragmentWrapper data={customerTestimonial2} />
				<CTAWrapper>
					<MasterCTA cta={cta} />
				</CTAWrapper>
			</Container>
		</Layout>
	);
};

export const query = graphql`
	query {
		contentfulEnterpriseChatbot {
			heroSection {
				...infoSectionFragment
			}
			section2Title
			section2Images {
				...asset
			}
			infoSection1 {
				...infoSectionFragment
			}
			customerTestimonial1 {
				...customerTestimonial
			}
			infoCards1 {
				...infoSectionFragment
			}
			infoSection2 {
				...infoSectionFragment
			}
			customerTestimonial2 {
				...customerTestimonial
			}
			cta {
				...cta
			}
			seo {
				...seo
			}
		}
	}
`;

export default EnterpriseChatbot;
