import React from 'react';
import { Section } from '../../home-page/about-chatbots/styles';
import SectionTextBox from '../section-textbox/SectionTextBox';
import ImageWrapper from '../ImageWrapper/ImageWrapper';

const InfoSectionFragmentWrapper = ({ data }) => {
	return (
		<Section>
			<SectionTextBox
				title={data.title}
				subtitle={data.subtitle?.subtitle}
				largeHeading="true"
				link={data.button?.linkUrl}
				btnText={data.button?.linkText}
			/>
			<ImageWrapper
				image={data.image}
				otherStyles={{ maxWidth: '1150px', margin: '0 auto', width: '100%' }}
			/>
		</Section>
	);
};

export default InfoSectionFragmentWrapper;
